.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 10;
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  scrollbar-width: none;
}
.headerContainer::-webkit-scrollbar {
  display: none;
}
.headerContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.headerContainer::-webkit-scrollbar {
  background-color: transparent !important;
}

.headerContainer::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}
.headerContainer::-webkit-scrollbar-thumb {
  background-color: rgb(151, 71, 255);
}
.logo {
  width: 39px;
  height: 44px;
  margin-right: 20px;
}
.styles .headerNavigation {
  display: flex;
  gap: 20px;
}
.headerNavigationItem {
}

.fakeChartScrollBar {
  position: absolute;
  bottom: 0px;
  left: 0;
  /* width: 33.33%;
  max-width: 33.33% !important; */
  height: 2px;
  background: #9747ff;
  /* display: none; */
  border-radius: 6px;
  z-index: 3;
}
.userIcon {
  cursor: pointer;
}

.headerAvatar {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
}
