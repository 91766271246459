.back-test-dropdown {
    display: flex;
    flex-direction: column;
    height: 40px;
    max-height: 40px;
    cursor: pointer;
    transition-duration: 0.2s;
    user-select: none;
    width: 135px;
    background-color: rgb(19, 14, 45);
    margin-right: 10px;
    &__selected {
        z-index: 4;
        min-height: 40px;
        width: 135px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-left: 10px;
        border-radius: 6px;
        position: relative;
        border: 0.5px solid rgba(99, 89, 154, 0.5);
        border-radius: 6px;
        margin-bottom: 3px;
        &_name {
            font-family: Inter;
            letter-spacing: 0px;
            text-align: left;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
        }
        &_arrow {
            top: 50%;
            right: 5px;
            position: absolute;
            transform: translateY(-50%);
            transition-duration: 0.3s;
        }
        &_arrowOpened {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    &__itemsContainer {
        max-height: 180px;
        min-height: 180px;
        z-index: 10;
        overflow: scroll;
        background-color: rgb(19, 14, 45);

        border-radius: 6px;
        border: 0.5px solid rgba(99, 89, 154, 0.5);
    }
    &__item {
        background-color: rgb(19, 14, 45);
        z-index: 6;
        min-height: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 0.5px solid rgba(99, 89, 154, 0.5);
        &__numbersOnly {
            height: 33px;
            max-height: 33px;
            min-height: 33px;
        }
        &_name {
            font-family: Inter;
            letter-spacing: 0px;
            text-align: left;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
        }
    }
    &__item:hover {
        background: rgb(67, 57, 122);
    }
}
