.strategies {
  padding: 18px 10px 13px;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 12px;

  &__wrap {
    width: 95%;
    flex: 1;
    overflow: auto;
    @media screen  and (max-width: 768px) {
      min-height:80px;
      overflow: visible;
    }
  }
  &__wrap > div:last-child {
    margin-bottom: 80px;
    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
  &.green-gradient {
    background: linear-gradient(0deg, rgba(48, 217, 96, 0.4) 0%, rgba(0, 0, 0, 0) 79%);
  }

  &.red-gradient {
    background: linear-gradient(0deg, #aa1b2c8a 0%, rgba(0, 0, 0, 0) 79%);
  }

  &.gray-gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.27) 0%, rgba(153, 153, 153, 0.108) 100%);
  }

  &__head {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__name {
      font-weight: 600;
      font-size: 16px;
      color: #fff;
    }

    &__periods {
      display: flex;
      gap: 5px;

      &__item {
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }

  &__price {
    margin-bottom: 12px;
    text-align: right;
    color: #30d960;
    font-weight: bold;
    font-size: 18px;
    span {
      font-weight: 400;
      font-size: 12px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px 23px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      color: #acacac;
      span {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}
