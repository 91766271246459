.tickerSelectorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.tickerSelector {
  position: fixed;
  width: 459px;
  max-width: 100%;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10, 7, 26, 1);
  border-radius: 0.5vh;
  box-shadow: 0 6px 12px #0000004d;

  display: flex;
  flex-direction: column;
  padding-bottom: 82px;
}
@media screen and (max-width: 768px) {
  .tickerSelector {
    width: 100%;
    height: 100%;
    padding: 24px 16px;
  }
}
.tickerSelector input {
  width: 90%;
  min-height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  margin: 1% 5%;
  padding: 2% 3%;
  background: #262336;
  border-radius: 8px;
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .tickerSelector input {
    width: 100%;
    margin: 0;
    min-height: 56px;
    border-radius: 8px;
  }
}

.tickerSelector input::placeholder {
  color: #838383;
}

.tickerSelector input:focus {
  border: 1px solid #9747ff;
}

.tickerSelectorHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5vh 3vh;
  border-bottom: 0.1vh solid rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .tickerSelectorHeader {
    padding: 0;
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
  }
}

.tickerSelectorHeader span {
  font-weight: 700;
  font-size: 2vh;
}
@media screen and (max-width: 768px) {
  .tickerSelectorHeader span {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
  }
}

.tickerSelectorHeaderClose {
  aspect-ratio: 1 / 1;
  background: none;
  fill: white;
}

.tickerSelectorHeaderClose:hover {
  fill: #9747ff;
}

.tickerSelectorContent {
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  user-select: none;
}
@media screen and (max-width: 768px) {
  .tickerSelectorContent {
    margin-top: 10px;
  }
}

.tickerSelectorContent div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  width: 90%;
  margin: 1% 2.5%;
  height: 5vh;
  padding: 2% 3%;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 5px;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 20px;
  padding-left: 12px;
}
@media screen and (max-width: 768px) {
  .tickerSelectorContent div {
    width: 100%;
    margin: 1% 0%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    min-height: 44px;
  }
}
.tickerSelectorContent span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
/* .tickerSelectorContent span img {
  margin-right: 12px;
} */
.tickerSelectorContentImage {
  height: 2vh;
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
  .tickerSelectorContentImage {
    height: 24px;
  }
}
.tickerSelectionIndicatorImage {
  margin-left: 12px;
}
.tickerSelectorContent div:hover {
  background-color: #382b5e;
}
.tickerSelectorSearchingHeader {
  width: 90%;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  padding-left: 12px;
  padding-right: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 5px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .tickerSelectorSearchingHeader {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}
.tickerSelectorSearchingHeader:hover {
  background-color: #382b5e;
}
.tickerSelectorSearchingHeader div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 134px;
}
.tickerSelectorSearchingHeader img {
  width: 24px;
  height: 24px;
}
.tickerSelectorCreatingHeader {
  width: 90%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  padding-left: 12px;
  padding-right: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 5px;
  color: white;
}
@media screen and (max-width: 768px) {
  .tickerSelectorCreatingHeader {
    padding-left: 0px;
  }
}
.tickerSelectorCreatingSubHeader {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  margin: 12px 0;
  color: white;
  padding: 0% 6%;
  /* padding: 2% 3%; */
}
.tickerSelectorCreatingHeader img {
  transform: rotate(180deg);
  margin-right: 12px;
}
@media screen and (min-width: 768px) {
  .tickerSelectorCreatingHeader:hover {
    background-color: #382b5e;
  }
}

.tickerSelectedTickersSubHeader {
  padding: 2% 6%;
  color: white;
}
@media screen and (max-width: 768px) {
  .tickerSelectedTickersSubHeader {
    padding: 1% 6%;
  }
}
.tickerSelectedTickersContent {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  overflow-y: visible;
  height: auto;
  max-height: 300px;
  margin-top: 5px;
}
.tickerSelectedTickersContent div {
  background: #382b5e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  width: 90%;
  margin: 1% 2.5%;
  height: 5vh;
  padding: 2% 3%;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 4px;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 20px;
  padding-left: 12px;
  border-left: 2px solid rgba(151, 71, 255, 1);
}
@media screen and (max-width: 768px) {
  .tickerSelectedTickersContent div {
    margin-left: 0px;
    width: 100%;
  }
}
.tickerSelectedTickersContent span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
/* .tickerSelectedTickersContent span img {
  margin-right: 12px;
  margin-left: 12px;
} */
.tickerSelectedTickersContent div img {
  height: 2vh;
}

.tickerSelectedTickersContent div:hover {
  background-color: #382b5e;
}
.tickerSelectorFooter {
  display: flex;
  justify-content: space-between;
  padding: 3% 2% 5% 2%;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.tickerSelectorFooterClear {
  width: 44%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #262336;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.tickerSelectorFooterNext {
  width: 44%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tickerSelectorFooter {
    width: calc(100% - 12px);
    height: 108px;
    flex-direction: column-reverse;
    bottom: 24px;
    padding: 0;
    padding-top: 10px;
    background: rgba(10, 7, 26, 1);
  }
  .tickerSelectorFooterClear {
    width: calc(100% - 24px);
    min-height: 44px;
  }
  .tickerSelectorFooterNext {
    width: calc(100% - 24px);
    min-height: 44px;
  }
}
.tickerSelectorRevertContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.tickerSelectorRevert {
  height: 2.5vw;
  max-height: 36px;
  min-width: 77px;
  width: 77px;
  background: #694ef0;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #694ef0;
  font-size: 10px;
  margin-top: 12px;
  margin-right: 12px;
}
.tickerSelectorRevert:hover {
  background: #6052b3;
}
.tickerSelectionIcon {
  margin-left: 12px;
}

.tickerSettingsTickersContent {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  overflow-y: visible;
  height: auto;
  max-height: 565px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 768px) {
  .tickerSettingsTickersContent {
    padding-left: 0px;
    padding-right: 0px;
    max-height: 62%;
  }
}
.tickerSettingsTickersContentItem {
  border-radius: 12px 12px 0px 0px;
  background: #170e32;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  width: 100%;
  margin: 1% 0%;
  height: 5vh;
  padding: 2% 3%;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 5px;
  line-height: normal;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .tickerSettingsTickersContentItem {
    height: 46px;
    min-height: 46px;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 375px) {
  .tickerSettingsTickersContentItemName {
    width: 60%;
    overflow: hidden;
  }
}
.tickerSettingsTickersContentItem input {
  display: flex;
  width: 70px;
  height: 26px;
  min-height: 26px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #262336;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-right: 5px;
}
.tickerSettingsTickersContentItemLockImage {
  width: 24px !important;
  height: 24px !important;
  margin-left: 5px;
}
.tickerSettingsTickersContentItemLockImage img {
  margin-left: 10px;
}

.tickerSettingsTickersContentItemInput input:hover + img {
  visibility: hidden;
}
.tickerSettingsTickersContent div input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.tickerSettingsTickersContent div input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.tickerSettingsTickersContentItem input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: rgb(151, 71, 255)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=)
    no-repeat center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1em;
}

.tickerSettingsTickersContentItem input[type='number']::-webkit-inner-spin-button,
.tickerSettingsTickersContentItem input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}

.tickerSettingsTickersContentItem span {
  display: flex;
  align-items: center;
  color: #fff;

  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
/* .tickerSelectedTickersContent span img {
  margin-right: 12px;
  margin-left: 12px;
} */
.tickerSettingsTickersContentItem img {
  height: 2vh;
}

.tickerSettingsTickersContentItem:hover {
  background-color: #382b5e;
}
.customIndexItem {
  width: 90%;
  margin-left: 20px;
  height: 48px;
}
.customIndexItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 100%;
}
.customIndexItemHeader div {
  display: flex;
  align-items: center;
}
.customIndexItemHeader img {
  margin-right: 12px;
}
.savedTicker {
  background: #0a071a;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 90%;
  padding-bottom: 12px;
  max-height: 300px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .savedTicker {
    width: 100%;
    max-height: 315px;
  }
}

.savedTickerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding-left: 20px;
  min-height: 48px;
  height: 48px;
  user-select: none;
  padding-right: 12px;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 6px;
}
.savedTickerHeader:hover {
  background: #382b5e;
}
.savedTickerHeaderName {
  display: flex;
  align-items: center;
  width: 60%;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.savedTickerHeaderOpenButton {
  width: 25px;
  min-height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.savedTickerHeaderOpenButton img {
  width: 24px;
}

.savedTickerHeaderName span {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.savedTickerHeaderName img {
  margin-right: 12px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}
@media screen and (max-width: 768px) {
  .savedTickerHeaderName input {
    background-color: transparent;
    border-radius: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    border: none;
    border-bottom: 1px solid #9747ff;
  }
  .savedTickerHeaderName input:focus {
    border-radius: 4px;
  }
}
.savedTickerButtonsContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  position: relative;
  padding: 12px;
}
.savedTickerCancelButton {
  display: flex;
  width: 45%;
  min-width: 45%;
  justify-content: center;
  align-items: center;
  height: 2.5vw;
  max-height: 36px;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: #262336;
  margin-right: 12px;
}
.savedTickerSaveButton {
  display: flex;
  width: 45%;
  min-width: 45%;
  justify-content: center;
  align-items: center;
  height: 2.5vw;

  max-height: 36px;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: #9747ff;
}
.savedTickerSeyEqual {
  width: 5.3vw;
  height: 2.5vw;
  max-height: 36px;
  border-radius: 4px;
  background: #694ef0;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
  max-width: 120px;
  max-height: 36px;
}
.savedTickerButtonsContainer div {
  display: flex;
  width: 55%;
}
@media screen and (max-width: 768px) {
  .savedTickerButtonsContainer {
    flex-direction: column-reverse;
    min-height: 100px;
    align-items: flex-end;
  }
  .savedTickerButtonsContainer div {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .savedTickerCancelButton {
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: left;
    width: 48%;
    height: 36px;
  }
  .savedTickerSaveButton {
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: left;
    width: 48%;
    height: 36px;
  }
  .savedTickerSeyEqual {
    width: 77px;
    height: 32px;
    min-height: 32px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.1px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tickerSelectorRevert {
    font-size: 3vw;
    width: 80px;
    min-height: 36px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 12.1px;
    text-align: center;
  }
}
.savedTickerListItem {
  border-radius: 12px 12px 0px 0px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  width: 100%;
  margin: 1% 0;
  height: 5vh;
  padding: 2% 4%;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 5px;
  line-height: normal;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .savedTickerListItem_name {
    width: 60%;
  }
}
.savedTickerListItem input {
  display: flex;
  width: 70px;
  height: 26px;
  min-height: 26px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #262336;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .savedTickerListItem input {
    margin-right: 5px;
  }
}
.savedTickerListItem span {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
.savedTickerListItem:first-child {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.savedTickerListItem input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: rgb(151, 71, 255)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=)
    no-repeat center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1em;
}
.savedTickerListItem img {
  height: 2vh;
}
@media screen and (max-width: 768px) {
  .savedTickerListItem img {
    height: 24px;
  }
}

.savedTickerListItem:hover {
  background-color: #382b5e;
}
.savedTickerMenu {
  position: absolute;
  width: 93px;
  height: 83px;
  border-radius: 6px;
  background: #170e32;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: -50px;
  left: -100px;
  padding-top: 5px;
}
.savedTickerMenuEditButton {
  display: flex;
  width: 73px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #9747ff;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.savedTickerMenuEditButton img {
  width: 14px;
  height: 14px;
}
.savedTickerMenuDeleteButton {
  display: flex;
  width: 73px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #d04243;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 12px;
}
.savedTickerMenuDeleteButton img {
  width: 14px;
  height: 14px;
}
.savedTickerListContent {
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  scrollbar-width: none;
  height: 65%;
  min-height: 65%;
  max-height: 65%;
}
