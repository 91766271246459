.back-test-switcher {
    width: 100%;
    height: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    &_item {
        width: 50%;
        height: 100%;
        display: flex;
        border-radius: 18px;
        //styleName: Button text/Button Active;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12.1px;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        &_selected {
            background: linear-gradient(90deg, #5413b7 0%, #250851 100%);
        }
    }
}
