.reversed {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(19, 14, 45, 1);
  left: 0;
  top: 0;
  padding: 12px;
  padding-top: 20px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    color: white;
    margin-bottom: 10px;

    &__back {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      margin-right: 20px;
      width: 65px;
      padding-right: 5px;
      cursor: pointer;

      &_img {
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../assets/images/Strategy/BackArrow.svg);
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin-bottom: 35px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    color: white;
    padding: 15px;
  }
}
