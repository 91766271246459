.strategy-editor {
  height: 100%;
  width: 100%;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  scrollbar-width: none;

  &__main {
    flex-grow: 1;
    width: 100%;
    min-height: 50px;
    padding: 12px 0 0 0;
    scrollbar-width: none;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      padding: 0;
      padding-bottom: 0px;
    }
  }
  &__back {
    min-width: 68px;
    min-height: 28px;
    background: #694ef0;
    border-radius: 4px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    margin-left: 16px;
    margin-bottom: 16px;
  }
}
.strategy-loader {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
}
