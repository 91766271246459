.strategy-metrics {
  position: relative;
  // margin-right: 10px;
  width: 390px;
  min-width: 390px;
  
  // margin-left: 20px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  background: #130e2d;
  height: 100%;
  &_perfomanceItem {
    width: 100%;
    min-height: 50px;
    background: linear-gradient(90deg, #432b92 0%, #292157 100%);
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    &_text {
      margin-bottom: 16.5px;
    }
    &_title{
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    span {
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
      line-height: 14.52px;
      text-align: left;
    }

    &_overall {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      background: linear-gradient(90deg, #9747ff 0%, #5b2b99 100%);
      margin-bottom: 0px;
      height: 233px;
    }
    &_text {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 700;
    }
    &_progressBar {
      background: rgba(69, 58, 120, 1);
      width: 100%;
      height: 4px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 10px;
      &_fill {
        background-color: rgba(151, 71, 255, 1);
        height: 100%;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
      }
    }
    &_progressFoto {
      width: 290px;
      height: 145px;
      background-image: url(../../../../assets/images/Strategy/RateBackground.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin-bottom: 12px;
      &_arrow {
        position: absolute;
        transform-origin: center left;
        bottom: -11px;
        left: 50%;
        transform: translateX(calc(-50% + 28px)) rotate(-90deg);
      }
      &_arrowContainer {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &_circle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: rgba(102, 102, 102, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 5;
          &_inner {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 1);
          }
        }
        &_line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: center left;
          transform: translate(calc(-50% + 45px), -50%) rotate(-183deg);
          width: 90px;
        }
      }
      &_strategyRate {
        font-size: 12px;
        line-height: 21px;
        font-weight: 500px;
        text-align: center;
      }
    }
    &_info {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }
  }
  &_icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  &__head {
    flex-grow: 1;
    margin-bottom: 10px;
    position: relative;
    background: #130e2d;
    padding: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 8px;
    overflow: hidden;
    .info {
    }
  }
  &__bottom {
    background: rgba(#171231, 0.7);
    padding: 6.5px 12px;
    border-radius: 6px;
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    span {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 106px;
    }
  }

  &__hide-trade {
    position: sticky;
    bottom: 0;
    left: 100%;
    display: inline-flex;
    align-items: center;
    background: #694ef0;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
    img {
      margin-left: 4px;
      transform: rotate(180deg);
    }
  }

  &__heading {
    display: flex;
    width: 390px;
    align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    gap: 12px;
    margin-bottom: 18px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    position: fixed;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 15px;
    // background: linear-gradient(180deg, #413dac 0%, #130e2d 100%);
    &_text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 12px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: linear-gradient(180deg, #4428c2 0%, #130e2d 100%);
    }
    img {
      display: flex;
      cursor: pointer;
      transform: rotate(90deg);
    }
  }
  &_btns {
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    border-radius: 4px;
    background-color: #2e2f4d80;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
    color: white;
    border-radius: 4px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
  &__prev,
  &__next {
    width: 20px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #694ef0;
    border-radius: 4px;
    &.disabled {
      cursor: default;
      background: #454154;
    }
  }

  &__next {
    margin-left: 10px;
    img {
      transform: rotate(180deg);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      span {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  &__date {
    margin-bottom: 8px;
    font-size: 10px;
    font-weight: 600;
  }

  &__red {
    color: #d04243;
  }
  &__green {
    color: #1dd100;
  }
  &__openTradesBtnContainer {
    cursor: pointer;
    &__btn {
      cursor: pointer;
      position: absolute;
      bottom: 18px;
      right: 8px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      background: rgba(105, 78, 240, 1);
      white-space: nowrap;
      padding: 0 12px;
      height: 32px;
      border-radius: 4px;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      color: #fff;
      img {
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    // min-height: 45%;
    background-color: rgb(10, 7, 26);
    min-width: unset;
    &__heading {
      position: static;
      width: 100%;
      margin-bottom: 10px;
      &_text {
        background: linear-gradient(180deg, #4428c2 0%, rgb(10, 7, 26) 100%);
      }
      img {
        display: none;
      }
    }
    &__head {
      border-radius: 0px;
      margin-bottom: 0px;
      background-color: rgba(10, 7, 26, 1);
      padding-bottom: 15%;
      margin-top: 14px;
      @media screen and (max-width: 768px) {
        margin-top: 0px;
        padding-bottom: 0px;
        overflow: unset;
      }
    }
    &__bottom {
      padding-top: 0px;
      min-height: 48%;
      max-height: 48%;
      height: 49%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: white;
      background: rgba(10, 7, 26, 1);
    }
    &__prev,
    &__next {
      width: 140px;
      height: 32px;
      img {
        width: 10px;
        height: 10px;
      }
    }
    &__date {
      font-size: 13px;
      margin-bottom: 3px;
    }
    &__list li {
      font-size: 13px;
      margin-bottom: 6px;
      span {
        font-size: 13px;
      }
    }
    &__nav {
      margin-top: 0px;
    }
  }
}
.strategy-metrics-customIndex {
  height: calc(100% - 77px);
}
.strategy-metrics-customIndexMerged {
  height: calc(100% - 96px);
}
.strategy-metrics-customIndexMergedCustomIndex {
  height: calc(100% - 115px);
}
