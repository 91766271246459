.create-merged-strategy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    color: white;
    height: 100%;
    overflow: scroll;
    position: relative;

    &__header {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
        color: white;
        width: 95%;
        margin-top: 18px;
        margin-bottom: 12px;
    }
    &__strategies {
        // max-height: 400px;
        //   min-height: 200px;
        // overflow: scroll;
        height: unset;
        width: 95%;
    }
    &__amount {
        width: 95%;
        min-height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: white;
        margin-bottom: 10px;
        &__inputContainer {
            display: flex;
            align-items: center;
            span {
                margin-left: 5px;
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.36px;
                text-align: left;
            }
        }
    }
    &__itemContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__itemInfo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            &_content {
                width: 87%;
                height: 54px;
                background-color: rgba(38, 35, 54, 1);
                border-radius: 6px;
                padding-left: 14px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &_name {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.94px;
                    text-align: left;
                    white-space: nowrap;
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &_description {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                }
            }
            img {
                cursor: pointer;
                margin-right: 10px;
            }
        }
        &__amountInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            &_input {
                display: flex;
                align-items: center;
            }
        }
    }

    &__addNew {
        width: 157px;
        min-height: 44px;
        height: 44px;
        background-color: rgba(105, 78, 240, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: left;
        border-radius: 4px;
        margin-top: 15px;
        cursor: pointer;
        img {
            margin-right: 3px;
        }
        margin-bottom: 80px;
        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    &__buttonContainer {
        width: 100%;
        min-height: 110px;
        height: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(19, 14, 45, 0.9);
        position: relative;
        position: absolute;
        bottom: 0;
        @media screen and (max-width: 768px) {
            height: unset;
            position: static;
            bottom: unset;
        }
    }
    &__merge {
        width: 180px;
        height: 44px;
        border-radius: 4px;
        background: rgba(151, 71, 255, 1);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: left;
        cursor: pointer;
        @media screen and (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }
    @media only screen and (max-width: 768px) {
        height: unset;
        padding-bottom: 20px;
        &__strategies {
            // min-height: 200px;
        }
    }
}
.merged-strategy-selector {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.merged-strategy-selector-container {
    width: 95%;
    height: 380px;
    background-color: rgba(38, 35, 54, 1);
    border-radius: 6px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__header {
        width: 100%;
        height: 54px;
        min-height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 300;
        line-height: 14.52px;
        text-align: left;
    }
    &__list {
        width: 100%;
        height: 230px;
        max-height: 230px;
        overflow: scroll;
        margin-bottom: 24px;
        color: white;

        &_item {
            padding: 0 7px;
            width: 100%;
            height: 50px;
            min-height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            cursor: pointer;
            transition-duration: 0.2s;
            border-radius: 6px;
            user-select: none;
            &_info {
                display: flex;
                flex-direction: column;
                &_name {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16.94px;
                    text-align: left;
                }
                &_currency {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                }
            }
        }
        &_item:hover {
            background-color: rgb(46, 43, 66);
        }
    }
    &__buttonsContainer {
        width: 100%;
        padding: 5px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__back {
            background-color: rgba(105, 78, 240, 1);
        }
        &__add {
            background-color: rgba(151, 71, 255, 1);
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48%;
            height: 44px;
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 12.1px;
            text-align: left;
            border-radius: 4px;
            color: white;
        }
    }
    @media screen and (max-width: 768px) {
        height: 80%;
        &__list {
            height: calc(100% - 60px);
            max-height: calc(100% - 60px);
            margin-bottom: 5px;
            &_item {
                height: 60px;
            }
        }
    }
}
.strategy-switcher {
    display: flex;
    background: #262336;
    align-items: center;
    border-radius: 6px;
    gap: 1px;
    height: 40px;
    width: 95%;
    // margin-top: 32px;
    color: white;
    padding: 0px 5px 0px 5px;

    &__option {
        font-size: 14px;
        font-weight: 600;
        flex: 1 1 0;
        width: 100px;
        white-space: nowrap;
        cursor: pointer;
        padding: 0 10px 5px 10px;
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        color: white;
        text-align: center;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 15px;
        min-height: 45px;
        &__option {
            height: 35px;
            line-height: 35px;
        }
    }
}
