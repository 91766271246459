.info {
  font-family: "Inter", sans-serif;
  padding: 0px 12px 10px 12px;
  height: calc(100% - 60px);
  overflow: scroll;
  margin-top: 60px;
  &__elements {
    width: 100%;
  }
  &__elements {
    display: flex;

    margin-bottom: 10px;
    background: linear-gradient(90deg, #432b92 0%, #292157 100%);
    height: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px 17px;
    border-radius: 4px;
    @media screen and (max-width: 768px) {
      height: 76px;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    height: unset;
  }
  &__head {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    overflow: hidden;
  }
  &__btn {
    width: 50%;
    color: #fff;
    height: 28px;
    background: rgba(69, 65, 84, 1);
    font-size: 10px;

    &.active {
      border-radius: 2px;
      background: #694ef0;
      font-weight: 600;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;

    &__arrow {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: rgba(69, 65, 84, 1);

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        background: #694ef0;
      }
    }
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #acacac;
    font-size: 12px;
    gap: 10px;
    &-name {
      font-weight: 700;
      color: white;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }

      p {
        font-size: 14px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.94px;
        text-align: left;
      }
    }
    &-number {
      flex: 1;
      text-align: right;
      font-family: Inter;
      font-size: 12px;
      font-weight: 700 !important;
      line-height: 14.52px;
      text-align: left;
      padding-left: 5px;
    }

    &-subname {
      color: #acacac;
      font-weight: 400;
    }

    &:not(:last-child) {
      // margin-bottom: 18px;
    }

    & p {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
      & span {
        font-size: 13.5px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  &__subElement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #acacac;
    font-size: 12px;
    // flex: 1;
  }
  &__element-subname {
    font-family: Inter;
    font-size: 13.5px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
  &_quickBackTest {
    width: 100%;
    height: min-content;
    max-height: 100%;
    overflow: scroll;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 60px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
    &_header {
      width: 100%;
      height: 60px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      &_text {
        width: 225px;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(46, 47, 77, 0.5);
        border-radius: 4px;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12.1px;
        text-align: left;
      }
    }
    &__elements {
      max-width: 31%;
      width: 32%;
      height: 112px;
      @media screen and (max-width: 2100px) {
        max-width: 48%;
        width: 48%;
        min-width: 350px;
      }
      @media screen and (max-width: 1600px) {
        min-width: 95%;
        width: 95%;
      }
    }
    &__element {
      &-name {
        font-size: 16.5px !important;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      & p {
        font-size: 16.5px !important;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      &-number {
        flex: 1;
        text-align: right;
        font-family: Inter;
        font-size: 16.5px !important;
        font-weight: 700 !important;
        line-height: 14.52px;
        text-align: left;
        padding-left: 5px;
      }
    }
  }
}
